<template>
  <div class="content">
    <div class="container companyContainer">
      <div class="company">
        <div class="companyInfo">
          <div class="companyName">成都卡恩特医疗科技有限公司</div>
          <div class="companyDesc">
            &nbsp;&nbsp;&nbsp;&nbsp;
            公司全称成都卡恩特医疗科技有限公司。成立于2011年，是专业研发医疗数字化产品，医学影像输出、医疗数字化存储的高科技、高新技术型企业。公司吸纳优秀人才及经销商，采用专业设备和自主研发的软件系统，致力于将先进的医疗数字化产品带入到中国医疗市场。公司推出的新型医学影像输出打印解决方案和超声输出打印解决方案对社会、经济、人性化的医疗服务发展均具有重要的意义。公司办公地址位于历史悠久，著有“蜀中江南”美称的天府之国成都市高新区天府三街吉泰路666号福年广场T2
            1204-1205。公司现拥有一批高学历、高素质的人才队伍，并与世界500强企业维持长期稳定的合作伙伴关系。秉承信守“品质第一、专业、高效”的经营理念，公司以优异的产品质量、专业的服务水平赢得了广大医院客户和行业专家们的一致认可。
          </div>
        </div>
        <div class="companyVideoArea">
        <!--  https://knot-website.oss-cn-beijing.aliyuncs.com/video/about.mp4-->
          <video
            preload="auto"
            autoplay
            muted
            controls
            ref="companyVideo"
            class="companyVideo"
            src="https://sichuan-2.zos.ctyun.cn/knot-website/video/about.mp4"
          ></video>
          <!-- :class="companyVideoPlay ? 'vedioButton' : 'vedioButtonPause'" -->
          <div
            :style="isOnclick ? 'font-size: 60px;transition: 6s;' : 'display:none'"
            :class="
              companyVideoPlay
                ? 'iconfont icon-24gf-pauseCircle'
                : 'iconfont icon-24gf-playCircle'
            "
            @click="videoControl"
          ></div>
          <!-- <i class="iconfont icon-24gf-pauseCircle"></i> -->
        </div>
      </div>
    </div>
    <!-- <div class="companyStrategy">
      <h3 class="companyStrategyTitle">企业战略</h3>
      <h4 class="companyStrategyDesc">
        根据市场发展，我们全力施行四大战略方针
      </h4>
      <div class="companyStrategyCards">
        <div class="companyStrategyCard">
          <el-image
            class="companyStrategyCardImg"
            fit="cover"
            :src="require('../../assets/images/web/about/fazhan.png')"
          ></el-image>
          <h3 class="companyStrategyCardTitle">01&nbsp;&nbsp;发展战略</h3>
          <p class="companyStrategyCardDesc">
            卡恩特医疗始终秉承“为患者、医生、医院创造价值”的经营理念，打造一批专业化的研发运营团队，以互联网医疗及大健康领域作为发展方向，致力于为患者、医生、医院服务，促进患者更便利地获取优质医疗资源，利用远程医疗、移动会诊等提升医生、医院的就诊效率。
          </p>
        </div>
        <div class="companyStrategyCard">
          <el-image
            class="companyStrategyCardImg"
            fit="cover"
            :src="require('../../assets/images/web/about/cp.png')"
          ></el-image>
          <h3 class="companyStrategyCardTitle">02&nbsp;&nbsp;产品战略</h3>
          <p class="companyStrategyCardDesc">
            以“指云端医疗”为中心的产品战略，坚持和巩固健康档案、远程医疗、移动会诊、电子处方、医药配送的核心能力建设，引入第三方合作伙伴共同完善互联网医疗生态圈，不断拓展与国内健康领先企业的合作，共同完善和打造“指云端智慧医疗服务平台”，为患者、医生、医院提供丰富的医疗健康服务功能。
          </p>
        </div>
        <div class="companyStrategyCard">
          <el-image
            class="companyStrategyCardImg"
            fit="cover"
            :src="require('../../assets/images/web/about/xs.png')"
          ></el-image>
          <h3 class="companyStrategyCardTitle">03&nbsp;&nbsp;销售战略</h3>
          <p class="companyStrategyCardDesc">
            “指云端医疗”以“一指存云、互惠三端”为初衷，坚持以医院零投入、患者低消费的销售核心战略；坚持以地区标杆医院建设为中心，不断辐射周边医院的拓展理念；积极打造医联体、医共体的远程医疗示范区；坚持以医疗生态建设，不断丰富大健康领域的产品，建设一批有高价值的功能，提高衍生产品的销售额度。
          </p>
        </div>
        <div class="companyStrategyCard">
          <el-image
            class="companyStrategyCardImg"
            fit="cover"
            :src="require('../../assets/images/web/about/fwzl.png')"
          ></el-image>
          <h3 class="companyStrategyCardTitle">04&nbsp;&nbsp;服务战略</h3>
          <p class="companyStrategyCardDesc">
            “指云端医疗”以向医院提供优质健康档案托管服务、远程医疗服务、移动会诊服务为基础，以向患者提供全面的健康档案、就诊渠道、报告解读、药品配送等服务重点，以搭建患者、医生的就医桥梁为支撑点，不断提供客户的产品体验以及发挥互联网医疗的产品价值，为患者、医生、医院创造新的价值。
          </p>
        </div>
      </div>
    </div> -->
    <div class="container companyCultureContainer">
      <div class="companyCulture">
        <h3 class="companyCultureTitle">企业文化</h3>
        <h4 class="companyCultureSubtitle">
          以人为本，关爱员工，求真务实，自强创新
        </h4>
        <div class="companyCultureInfo">
          <el-image
            class="companyCultureInfoImg"
            fit="cover"
            :src="require('@/assets/images/web/about/wh.png')"
          ></el-image>
          <p class="companyCultureInfoDesc">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以人为本人是发展的根本目的，也是发展的根本动力，一切为了员工，一切依靠员工，二者的统一构成以人为本的完整内容。作为一个成长型的企业，卡恩特医疗树立了超越利润的社会目标，不以利润为唯一追求。<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;坚持人的价值高于物的价值，共同的价值高于个人的价值，客户价值和社会价值高于企业的生产价值和利润价值；坚持走创新驱动的发展道路，科技推动、管理创新，建设创新型企业，实现可持续发展；卡恩特医疗追求员工、企业、顾客的和谐共赢。
          </p>
        </div>
        <div class="companyCultureCards">
          <div class="companyCultureCard card1">
            <div class="companyCultureCardImg"></div>
            <h3 class="companyCultureCardTitle">求真</h3>
            <p class="companyCultureCardDesc">
              不断总结自身特点，持续探索客观规律，实现企业科学发展
            </p>
          </div>
          <div class="companyCultureCard card2">
            <div class="companyCultureCardImg"></div>
            <h3 class="companyCultureCardTitle">务实</h3>
            <p class="companyCultureCardDesc">
              一切从实际出发去实践，有理想但不理想化
            </p>
          </div>
          <div class="companyCultureCard card3">
            <div class="companyCultureCardImg"></div>
            <h3 class="companyCultureCardTitle">自强</h3>
            <p class="companyCultureCardDesc">
              坚持自立、自信、自勉、自责，脚踏实地，百折不挠
            </p>
          </div>
          <div class="companyCultureCard card4">
            <div class="companyCultureCardImg"></div>
            <h3 class="companyCultureCardTitle">创新</h3>
            <p class="companyCultureCardDesc">
              与时俱进、勇于变革，拓展 发展方向、开创发展道路
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="companyVision">
      <h3 class="companyVisionTitle">使命愿景</h3>
      <h4 class="companyVisionSubtitle">
        我们从当下开始，为将来做好准备，它将成为我们的事业设立的目标
      </h4>
      <div class="companyVisionCards">
        <div class="companyVisionCard">
          <div class="companyVisionCardContent">
            <h3 class="companyVisionCardTitle">企业使命</h3>
            <p class="companyVisionCardDesc">
              为人民创造便利，为医疗行业贡献力量<br />做社会有责任的企业
            </p>
          </div>
        </div>
        <div class="companyVisionCard">
          <div class="companyVisionCardContent">
            <h3 class="companyVisionCardTitle">企业愿景</h3>
            <p class="companyVisionCardDesc">
              成为国内一流的互联网医疗企业<br />打造中国最专业的健康医疗平台
            </p>
          </div>
        </div>
      </div>
    </div>
    <smallfooter></smallfooter>
  </div>
</template>

<script>
import smallfooter from './../../components/webFooter/index.vue'
export default {
  components: {
    smallfooter
  },
  data() {
    return {
      companyVideoPlay: true,
      isOnclick: false
    }
  },
  methods: {
    videoControl() {
      this.isOnclick = true
      if (this.companyVideoPlay) {
        console.log('暂停')
        this.$refs.companyVideo.pause()
      } else {
        this.$refs.companyVideo.play()
        console.log('播放')
      }
      this.companyVideoPlay = !this.companyVideoPlay
      setTimeout(() => {
        this.isOnclick = false
      }, 20000)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  .container {
    width: 100%;
    display: inline-block;
  }
  .companyContainer {
    height: 600px;
    background: url('../../assets/images/web/about/KNOT.png') no-repeat;
    background-size: 100% 100%;
    .company {
      display: flex;
      flex-direction: row;
      width: 1200px;
      margin: 0 auto;
      margin-top: 106px;
      .companyInfo {
        .companyName {
          height: 36px;
          padding: 0;
          font-size: 36px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
        }
        .companyDesc {
          margin-top: 41px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.65);
          line-height: 29px;
          letter-spacing: 2px;
          text-align: justify;
        }
      }
      .companyVideoArea {
        &:hover {
          //  transition: 6s;
          .vedioButton {
            display: block;
            // width: 114px;
            // height: 114px;
            // transition: 2s;
          }
        }
        position: relative;
        width: 612px;
        height: 357px;
        margin-left: 60px;
        .companyVideo {
          width: 612px;
          height: 357px;
        }
        .iconfont {
          // display: none;
          position: absolute;
          top: 137px;
          left: 278px;
          font-size: 30px;
          color: #fff;

          border-radius: 50%;
          background-color: #000;

          // background: url('../../assets/images/web/about/bofang.png') no-repeat;
          // background-size: 100% 100%;
          // width: 57px;
          // height: 57px;
        }
        .vedioButtonPause {
          position: absolute;
          top: 137px;
          left: 278px;
          background: url('../../assets/images/web/about/bofang.png') no-repeat;
          background-size: 100% 100%;
          width: 57px;
          height: 57px;
        }
      }
    }
  }
  .companyStrategy {
    overflow: hidden;
    width: 1200px;
    height: 1727px;
    margin: 0 auto;
    .companyStrategyTitle {
      font-size: 52px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #29262a;
      text-align: center;
      margin-top: 88px;
    }
    .companyStrategyDesc {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #606062;
      text-align: center;
      margin-top: 37px;
    }
    .companyStrategyCards {
      width: 1200px;
      height: 1285px;
      margin: 0 auto;
      margin-top: 46px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      .companyStrategyCard {
        border: 2px solid #fff;
        &:nth-child(2n + 1) {
          margin-left: 0px;
        }
        margin-top: 44px;
        margin-left: 25px;
        width: 583.5px;
        height: 621px;
        background: #ffffff;
        box-shadow: 0px 7px 24px 0px rgba(12, 41, 72, 0.16);
        .companyStrategyCardImg {
          width: 530px;
          height: 300px;
          margin: 0 auto;
          margin: 28px;
        }
        .companyStrategyCardTitle {
          font-size: 26px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #3c3c3c;
          width: 530px;
          margin: 0 auto;
          margin-top: 33px;
        }
        .companyStrategyCardDesc {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 35px;
          width: 530px;
          margin: 0 auto;
          margin-top: 20px;
        }
      }
      .companyStrategyCard:hover {
        border: 2px solid #559ef3;
      }
    }
  }
  .companyCultureContainer {
    height: 1200px;
    overflow: hidden;
    background: #f9f9f9;
    margin: 0 auto;
    .companyCultureTitle {
      font-size: 45px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #29262a;
      text-align: center;
      margin-top: 58px;
    }
    .companyCultureSubtitle {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #606062;
      line-height: 40px;
      text-align: center;
      margin-top: 35px;
    }
    .companyCultureInfo {
      width: 1200px;
      margin: 0 auto;
      margin-top: 107px;
      display: flex;
      flex-direction: row;
      height: 347px;
      .companyCultureInfoImg {
        width: 518px;
        height: 347px;
      }
      .companyCultureInfoDesc {
        width: 654px;
        margin-left: 52px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(60, 60, 60, 0.65);
        line-height: 40px;
        letter-spacing: 1.41px;
        text-align: justify;
      }
    }
    .companyCultureCards {
      width: 1200px;
      height: 395px;
      margin: 0 auto;
      margin-top: 88px;
      display: flex;
      flex-direction: row;
      .companyCultureCard {
        font-weight: 700;
        &:hover {
          transform: translate(0, -30px);
          background: #0065b3;
          border: 3px solid #0065b3;
          font-weight: 700;
          .companyCultureCardTitle {
            color: white;
            font-weight: 700;
          }
          .companyCultureCardDesc {
            color: white;
          }
        }
        &:first-child {
          margin-left: 0px;
        }
        margin-left: 32px;
        width: 288px;
        height: 395px;
        border-radius: 10px;
        border: 3px solid #ece9e9;
        .companyCultureCardImg {
          width: 87px;
          height: 87px;
          margin: 0 auto;
          margin-top: 62px;
        }
        .companyCultureCardTitle {
          font-weight: 700;
          letter-spacing: 6px;
          margin-top: 36px;
          text-align: center;
          font-size: 20px;
          font-family: PingFang SC;
          color: #2c2c2c;
        }
        .companyCultureCardDesc {
          width: 202px;
          margin: 0 auto;
          margin-top: 32px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 27px;
        }
      }
    }
    .card1 {
      .companyCultureCardImg {
        background: url('../../assets/images/web/about/qz-w.png') no-repeat;
        background-size: 100% auto;
      }
      &:hover {
        .companyCultureCardImg {
          background: url('../../assets/images/web/about/qz-y.png') no-repeat;
          background-size: 100% auto;
        }
      }
    }
    .card2 {
      .companyCultureCardImg {
        background: url('../../assets/images/web/about/ws-w.png') no-repeat;
        background-size: 100% auto;
      }
      &:hover {
        .companyCultureCardImg {
          background: url('../../assets/images/web/about/ws-y.png') no-repeat;
          background-size: 100% auto;
        }
      }
    }
    .card3 {
      .companyCultureCardImg {
        background: url('../../assets/images/web/about/zq-w.png') no-repeat;
        background-size: auto 100%;
      }
      &:hover {
        .companyCultureCardImg {
          background: url('../../assets/images/web/about/zq-y.png') no-repeat;
          background-size: auto 100%;
        }
      }
    }
    .card4 {
      .companyCultureCardImg {
        background: url('../../assets/images/web/about/cx-w.png') no-repeat;
        background-size: 100% auto;
      }
      &:hover {
        .companyCultureCardImg {
          background: url('../../assets/images/web/about/cx-y.png') no-repeat;
          background-size: 100% auto;
        }
      }
    }
  }
  .companyVision {
    width: 1200px;
    height: 700px;
    margin: 0 auto;
    .companyVisionTitle {
      font-size: 45px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #29262a;
      margin-top: 86px;
      text-align: center;
    }
    .companyVisionSubtitle {
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #2c2c2c;
      line-height: 40px;
      margin-top: 36px;
      text-align: center;
    }
    .companyVisionCards {
      width: 100%;
      height: 370px;
      margin-top: 74px;
      display: flex;
      flex-direction: row;
      .companyVisionCard {
        &:first-child {
          margin-left: 0px;
          background: url('../../assets/images/web/about/sm.png') no-repeat;
          background-size: 100% 100%;
        }
        &:last-child {
          background: url('../../assets/images/web/about/yj.png') no-repeat;
          background-size: 100% 100%;
        }
        width: 586px;
        height: 370px;
        margin-left: 52px;
        .companyVisionCardContent {
          overflow: hidden;
          width: 519px;
          height: 204px;
          box-shadow: 0px 2px 13px 0px rgba(196, 196, 196, 0.48);
          background: rgba(255, 255, 255, 0.91);
          margin: 0 auto;
          margin-top: 144px;
          .companyVisionCardTitle {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 800;
            color: #3c3c3c;
            text-align: center;
            margin-top: 37px;
          }
          .companyVisionCardDesc {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #5a5858;
            line-height: 46px;
            text-align: center;
            margin-top: 29px;
          }
        }
      }
    }
  }
}
</style>
