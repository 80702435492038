var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"container companyContainer"},[_c('div',{staticClass:"company"},[_vm._m(0),_c('div',{staticClass:"companyVideoArea"},[_c('video',{ref:"companyVideo",staticClass:"companyVideo",attrs:{"preload":"auto","autoplay":"","muted":"","controls":"","src":"https://sichuan-2.zos.ctyun.cn/knot-website/video/about.mp4"},domProps:{"muted":true}}),_c('div',{class:_vm.companyVideoPlay
              ? 'iconfont icon-24gf-pauseCircle'
              : 'iconfont icon-24gf-playCircle',style:(_vm.isOnclick ? 'font-size: 60px;transition: 6s;' : 'display:none'),on:{"click":_vm.videoControl}})])])]),_c('div',{staticClass:"container companyCultureContainer"},[_c('div',{staticClass:"companyCulture"},[_c('h3',{staticClass:"companyCultureTitle"},[_vm._v("企业文化")]),_c('h4',{staticClass:"companyCultureSubtitle"},[_vm._v(" 以人为本，关爱员工，求真务实，自强创新 ")]),_c('div',{staticClass:"companyCultureInfo"},[_c('el-image',{staticClass:"companyCultureInfoImg",attrs:{"fit":"cover","src":require('@/assets/images/web/about/wh.png')}}),_vm._m(1)],1),_vm._m(2)])]),_vm._m(3),_c('smallfooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"companyInfo"},[_c('div',{staticClass:"companyName"},[_vm._v("成都卡恩特医疗科技有限公司")]),_c('div',{staticClass:"companyDesc"},[_vm._v("      公司全称成都卡恩特医疗科技有限公司。成立于2011年，是专业研发医疗数字化产品，医学影像输出、医疗数字化存储的高科技、高新技术型企业。公司吸纳优秀人才及经销商，采用专业设备和自主研发的软件系统，致力于将先进的医疗数字化产品带入到中国医疗市场。公司推出的新型医学影像输出打印解决方案和超声输出打印解决方案对社会、经济、人性化的医疗服务发展均具有重要的意义。公司办公地址位于历史悠久，著有“蜀中江南”美称的天府之国成都市高新区天府三街吉泰路666号福年广场T2 1204-1205。公司现拥有一批高学历、高素质的人才队伍，并与世界500强企业维持长期稳定的合作伙伴关系。秉承信守“品质第一、专业、高效”的经营理念，公司以优异的产品质量、专业的服务水平赢得了广大医院客户和行业专家们的一致认可。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"companyCultureInfoDesc"},[_vm._v("       以人为本人是发展的根本目的，也是发展的根本动力，一切为了员工，一切依靠员工，二者的统一构成以人为本的完整内容。作为一个成长型的企业，卡恩特医疗树立了超越利润的社会目标，不以利润为唯一追求。"),_c('br'),_vm._v("      坚持人的价值高于物的价值，共同的价值高于个人的价值，客户价值和社会价值高于企业的生产价值和利润价值；坚持走创新驱动的发展道路，科技推动、管理创新，建设创新型企业，实现可持续发展；卡恩特医疗追求员工、企业、顾客的和谐共赢。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"companyCultureCards"},[_c('div',{staticClass:"companyCultureCard card1"},[_c('div',{staticClass:"companyCultureCardImg"}),_c('h3',{staticClass:"companyCultureCardTitle"},[_vm._v("求真")]),_c('p',{staticClass:"companyCultureCardDesc"},[_vm._v(" 不断总结自身特点，持续探索客观规律，实现企业科学发展 ")])]),_c('div',{staticClass:"companyCultureCard card2"},[_c('div',{staticClass:"companyCultureCardImg"}),_c('h3',{staticClass:"companyCultureCardTitle"},[_vm._v("务实")]),_c('p',{staticClass:"companyCultureCardDesc"},[_vm._v(" 一切从实际出发去实践，有理想但不理想化 ")])]),_c('div',{staticClass:"companyCultureCard card3"},[_c('div',{staticClass:"companyCultureCardImg"}),_c('h3',{staticClass:"companyCultureCardTitle"},[_vm._v("自强")]),_c('p',{staticClass:"companyCultureCardDesc"},[_vm._v(" 坚持自立、自信、自勉、自责，脚踏实地，百折不挠 ")])]),_c('div',{staticClass:"companyCultureCard card4"},[_c('div',{staticClass:"companyCultureCardImg"}),_c('h3',{staticClass:"companyCultureCardTitle"},[_vm._v("创新")]),_c('p',{staticClass:"companyCultureCardDesc"},[_vm._v(" 与时俱进、勇于变革，拓展 发展方向、开创发展道路 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"companyVision"},[_c('h3',{staticClass:"companyVisionTitle"},[_vm._v("使命愿景")]),_c('h4',{staticClass:"companyVisionSubtitle"},[_vm._v(" 我们从当下开始，为将来做好准备，它将成为我们的事业设立的目标 ")]),_c('div',{staticClass:"companyVisionCards"},[_c('div',{staticClass:"companyVisionCard"},[_c('div',{staticClass:"companyVisionCardContent"},[_c('h3',{staticClass:"companyVisionCardTitle"},[_vm._v("企业使命")]),_c('p',{staticClass:"companyVisionCardDesc"},[_vm._v(" 为人民创造便利，为医疗行业贡献力量"),_c('br'),_vm._v("做社会有责任的企业 ")])])]),_c('div',{staticClass:"companyVisionCard"},[_c('div',{staticClass:"companyVisionCardContent"},[_c('h3',{staticClass:"companyVisionCardTitle"},[_vm._v("企业愿景")]),_c('p',{staticClass:"companyVisionCardDesc"},[_vm._v(" 成为国内一流的互联网医疗企业"),_c('br'),_vm._v("打造中国最专业的健康医疗平台 ")])])])])])
}]

export { render, staticRenderFns }